import * as React from "react";

const FitplanIcon = ({
  style = { height: "41px" },
  width = "40px",
  height = "41px",
  className = "",
  viewBox = "0 0 40 41",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0 39.9999766 0 39.9999766 40 0 40" />
    </defs>
    <g
      id="Account-Manager"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="account-overview-desktop-1440px"
        transform="translate(-364.000000, -682.000000)"
      >
        <g id="fitplan-icon" transform="translate(364.000000, 682.575820)">
          <mask id="mask-2" fill="white">
            <use href="#path-1" />
          </mask>
          <g id="Clip-2" />
          <path
            d="M35.2678672,40 L4.73216406,40 C2.11864844,40 -2.34375e-05,37.8814062 -2.34375e-05,35.2678906 L-2.34375e-05,4.73210937 C-2.34375e-05,2.11867187 2.11864844,0 4.73216406,0 L35.2678672,0 C37.8813828,0 39.9999766,2.11867187 39.9999766,4.73210937 L39.9999766,35.2678906 C39.9999766,37.8814062 37.8813828,40 35.2678672,40"
            id="Fill-1"
            fill="#111213"
            mask="url(#mask-2)"
          />
          <g id="Group-4" mask="url(#mask-2)" fill="#FFFFFF">
            <g transform="translate(7.207618, 9.214285)">
              <polygon
                id="Fill-1"
                points="1.29341497 15.2962342 0 21.4419861 7.14532294 21.4419861 8.4388382 15.2962342"
              />
              <polygon
                id="Fill-2"
                points="2.89761258 7.64811708 1.60419761 13.793869 15.6283781 13.793869 16.9218934 7.64811708"
              />
              <polygon
                id="Fill-3"
                points="4.51819726 0 3.22478228 6.14585224 24.2409453 6.14585224 25.5343603 0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FitplanIcon;
